import { Divider, Flex, Menu, Typography } from "antd";
import router from "lib/router";
import { autorun, makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminSettingsAndroidSection } from "./admin-sections/AdminSettingsAndroidSection";
import { AdminSettingsOrganizationsSection } from "./admin-sections/AdminSettingsOrganizationsSection";
import { AdminSettingsPromotionalSection } from "./admin-sections/AdminSettingsPromotionalSection";
import { AdminSettingsRolesSection } from "./admin-sections/AdminSettingsRolesSection";
import { AdminSettingsSystemSection } from "./admin-sections/AdminSettingsSystemSection";
import { AdminSettingsUserAccessRuleSection } from "./admin-sections/AdminSettingsUserAccessRuleSection";
import { AdminSettingsUsersSection } from "./admin-sections/AdminSettingsUsersSection";

const Tabs: Array<{
  key: string,
  label: React.ReactNode;
  children: React.ReactNode;
}> = [
    {
      key: "",
      label: "System",
      children: <AdminSettingsSystemSection />,
    },
    {
      key: "promotional-game",
      label: "Promotional",
      children: <AdminSettingsPromotionalSection />,
    },
    {
      key: "android",
      label: "Android",
      children: <AdminSettingsAndroidSection />,
    },
    {
      key: "users",
      label: "Users",
      children: <AdminSettingsUsersSection />,
    },
    {
      key: "roles",
      label: "Roles",
      children: <AdminSettingsRolesSection />,
    },
    {
      key: "organizations",
      label: "Organizations",
      children: <AdminSettingsOrganizationsSection />,
    },
    {
      key: "user-access-rules",
      label: "User Access Rules",
      children: <AdminSettingsUserAccessRuleSection />,
    },
  ];

interface Props { }

export const AdminSettingsSection = observer((props: Props) => {
  class Store {
    currentTab: number = 0;

    constructor() {
      makeAutoObservable(this);

      autorun(() => {
        const tab = Tabs[this.currentTab]?.key ?? "";
        router.navigate(`/settings/admin/${tab}`);
      });
    }

    setCurrentTab(tab: number) {
      this.currentTab = tab;
    }
  }

  const { adminTab = "" } = useParams();
  const [store] = useState(() => new Store());

  useEffect(() => {
    const tabIndex = Math.max(Tabs.findIndex(({ key }) => key === adminTab), 0);
    store.setCurrentTab(tabIndex);
  }, [adminTab, store]);

  return (
    <>
      <div className="rounded-xl border-dashed border shadow p-1">
        <Flex>
          <Menu
            selectedKeys={[store.currentTab.toString()]}
            items={Tabs.map((value, index) => ({
              key: index.toString(),
              label: value.label,
            }))}
            onSelect={({ key }) => store.setCurrentTab(parseInt(key))}
          />

          <div className="p-6 flex-1">
            <Typography.Title level={3}>
              {Tabs[store.currentTab].label}
            </Typography.Title>

            <Divider />

            {Tabs[store.currentTab].children}
          </div>
        </Flex>
      </div>
    </>
  );
});
