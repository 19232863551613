import { Button, Input, Typography } from "antd";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { Observer, observer } from "mobx-react-lite";
import { useState } from "react";
import { FileSelector } from "../FileSelector";
import { BaseDrawer, IDrawerProps } from "./BaseDrawer";

interface Props extends IDrawerProps {
}

export const CreateProtionalGameDrawer = observer((props: Props) => {
  class Store {
    open: boolean = false;
    title: string = "";
    image?: File;
    genre: string = "";
    gameUrl?: string;

    constructor() {
      makeAutoObservable(this);
    }

    setGameUrl(url?: string) {
      this.gameUrl = url;
    }

    setImage(image?: File) {
      this.image = image;
    }

    setOpen(isOpen: boolean) {
      this.open = isOpen;
    }

    setTitle(title: string) {
      this.title = title;
    }

    setGenre(genre: string) {
      this.genre = genre;
    }

    get valid() {
      return this.title.trim() && this.genre.trim() && this.image;
    }
  }

  const [store] = useState(() => new Store());
  const { promotionalStore } = useGlobalStore();

  return (
    <>
      <BaseDrawer
        render={props.render}
        onOpenChanged={(open) => {
          store.setTitle("");
          store.setGenre("");
          store.setImage();
        }}>
        {
          (open, close) => (
            <Observer>
              {
                () => (
                  <>
                    <div className="mb-4">
                      <Typography.Title level={5}>Title</Typography.Title>

                      <Input
                        size="large"
                        value={store.title}
                        status={store.title.trim() ? undefined : "error"}
                        onChange={(e) => store.setTitle(e.target.value)}
                        count={{
                          max: 64,
                          show: true
                        }}
                      />
                    </div>

                    <div className="mb-4">
                      <Typography.Title level={5}>Genre</Typography.Title>

                      <Input
                        size="large"
                        value={store.genre}
                        status={store.genre.trim() ? undefined : "error"}
                        onChange={(e) => store.setGenre(e.target.value)}
                        count={{
                          max: 64,
                          show: true
                        }}
                      />
                    </div>

                    <div className="mb-4">
                      <Typography.Title level={5}>Image</Typography.Title>

                      <FileSelector
                        label="Upload Image"
                        file={store.image}
                        onChange={(file) => store.setImage(file)}
                      />
                    </div>

                    <div className="mb-4">
                      <Typography.Title level={5}>Game URL</Typography.Title>

                      <Input
                        size="large"
                        value={store.gameUrl}
                        onChange={(e) => store.setGameUrl(e.target.value)}
                        count={{
                          max: 256,
                          show: true
                        }}
                      />
                    </div>

                    <div className="mb-4">
                      <Button
                        onClick={() => promotionalStore.create({
                          image: store.image,
                          title: store.title,
                          genre: store.genre,
                          gameUrl: store.gameUrl,
                        }).then(() => promotionalStore.loadAll()).finally(close)}
                        loading={promotionalStore.isLoading}
                        disabled={!store.valid}
                      >
                        Create
                      </Button>
                    </div>
                  </>
                )
              }
            </Observer>
          )
        }
      </BaseDrawer>
    </>
  );
});
