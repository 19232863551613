import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Flex, Image, Table } from "antd";
import { Popconfirm } from "antd/lib";
import { CreateProtionalGameDrawer } from "app/components/drawers/CreatePromotionalGameDrawer";
import {
  PromotionalGame
} from "lib/models";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";


interface Props { }

export const AdminSettingsPromotionalSection = observer((props: Props) => {
  const { promotionalStore } = useGlobalStore();

  useEffect(() => {
    promotionalStore.loadAll().finally();
  }, [promotionalStore]);

  const reloadUsers = useCallback(() => { promotionalStore.loadAll() }, [promotionalStore]);
  const isLoading = promotionalStore.isLoading;

  return (
    <>
      <Flex justify="flex-end">
        <CreateProtionalGameDrawer
          render={(open, close) => (
            <Button size="large" onClick={open} type="primary" icon={<PlusCircleFilled />}>
              CREATE
            </Button>
          )}
        />
      </Flex>

      <div className="my-4" />

      <Table
        columns={[
          {
            title: "Image",
            dataIndex: "imageUrl",
            width: 100,
            render: (value) => (
              <Image
                src={value}
                fallback={"/icon.png"}
                width={64}
                height={64}
              />
            ),
            align: "center",
          },
          {
            title: "Title",
            dataIndex: "title",
          },
          {
            title: "Genre",
            dataIndex: "genre",
          },
          {
            title: "Action",
            render: (value: PromotionalGame) => (
              <>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() =>
                    promotionalStore.delete(value.id).then(reloadUsers)
                  }
                >
                  <Button
                    size="large"
                    icon={<DeleteOutlined />}
                    loading={isLoading}
                  />
                </Popconfirm>
              </>
            ),
            width: 60,
            align: "center",
          },
        ]}
        dataSource={promotionalStore.games}
        bordered
        size="large"
        loading={isLoading}
      />
    </>
  );
});
