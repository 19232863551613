import axios from "axios";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

export const system = {
    getAll: () => AxiosWrapper(axios.get<{ [id: string]: string }>("/system-setting", BaseAPIMainConfig)),

    get: (id: string) =>
        AxiosWrapper(axios.get<string | null>(`/system-setting/${id}`, BaseAPIMainConfig)),

    set: (
        id: string,
        value: string,
    ) => AxiosWrapper(axios.put(`/system-setting/${id}`, value, BaseAPIMainConfig)),
};
